import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import styles from './Guests_face.module.css';
import { FaEnvelope } from 'react-icons/fa';
import avat from './img/km.jpg';
import { UserActions } from '../../Store/userSlice';

export default function Guests() {
    const { id: eventId } = useParams();
    const dispatch = useDispatch();
    const compareSuccess = useSelector(state => state.user.compareSuccess);
    const [guests, setGuests] = useState([]);
    const [guestsMatchGuests, setMatchGuests] = useState([]);
    const [selectedGuest, setSelectedGuest] = useState(null);
    const [selectedGuestMatch, setSelectedGuestMatch] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [lightboxImage, setLightboxImage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchGuestDetails = async () => {
            setIsLoading(true);
            setError(null);
            try {
                const response = await axios.get('https://api.hapzea.com/api/v1/user/get-guest-details', {
                    params: { eventId },
                    withCredentials: true,
                });
                console.log('Fetched Guest Details:', response.data);
                setGuests(response.data.guests);
            } catch (error) {
                console.error('Error fetching guest details:', error);
                setError('Failed to load guest details. Please try again later.');
            } finally {
                setIsLoading(false);
            }
        };

        if (eventId) {
            fetchGuestDetails();
        } else {
            console.warn('No eventId found in the URL.');
            setError('Invalid event. No eventId provided.');
        }
    }, [eventId]);

    useEffect(() => {
        const fetchMatchedGuestDetails = async () => {
            console.log('Fetching matched guest details...');
            try {
                const response = await axios.get('https://api.hapzea.com/api/v1/user/guests-with-images', {
                    params: { eventId },
                    withCredentials: true,
                });
                console.log('Fetched Matched Guests:', response.data.data.guests);
                setMatchGuests(response.data.data.guests);
                dispatch(UserActions.setCompareSuccess(false));
            } catch (error) {
                console.error('Error fetching matched guest details:', error);
                setError('Failed to load matched guest details. Please try again later.');
            }
        };

        if (eventId) {
            fetchMatchedGuestDetails();
        } else {
            console.warn('No eventId found in the URL.');
            setError('Invalid event. No eventId provided.');
        }
    }, [eventId, compareSuccess, dispatch]);

    const handleGuestClick = (guest) => {
        const matchedGuest = guestsMatchGuests.find(matchedGuestItem => matchedGuestItem.guestId === guest.guestId);
        console.log('Selected Guest:', guest);
        console.log('Matched Guest:', matchedGuest);
        setSelectedGuestMatch(matchedGuest);
        setSelectedGuest(guest);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setSelectedGuest(null);
        setIsModalOpen(false);
    };

    const handleSendEmail = () => {
        if (selectedGuest) {
            const subject = `Hello ${selectedGuest.name}, Here Are Your Images`;
            const imageLinks = selectedGuest.matches
                ?.map((match, index) => `Image ${index + 1}: ${match.imageUrl}`)
                .join('\n');
            const body = `Dear ${selectedGuest.name},\n\nHere are your images:\n${imageLinks}\n\nBest regards,\nEvent Team`;
            const mailtoURL = `mailto:${selectedGuest.email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
            const newWindow = window.open(mailtoURL, '_blank');
            if (!newWindow) {
                alert('Failed to open email client. Please check your popup settings.');
            }
        }
    };

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape') {
                closeModal();
            }
        };

        if (isModalOpen) {
            window.addEventListener('keydown', handleKeyDown);
        } else {
            window.removeEventListener('keydown', handleKeyDown);
        }

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isModalOpen]);

    const handleImageClick = (imgSrc) => {
        setLightboxImage(imgSrc);
        setLightboxOpen(true);
    };

    return (
        <>
            <div className={styles.header}>
                <p className={styles.total_guests}>Total Guests: {guests.length}</p>
            </div>
            <div className={styles.guests_container}>
                <div className={styles.guests_list_container}>
                    <div className={styles.guest_items}>
                        {isLoading ? (
                            <p>Loading guests...</p>
                        ) : error ? (
                            <p className={styles.error_message}>{error}</p>
                        ) : guests.length > 0 ? (
                            guests.map((guest) => (
                                <div
                                    key={guest.guestId}
                                    className={styles.guest_item}
                                    onClick={() => handleGuestClick(guest)}
                                >
                                    <img
                                        src={guest.imageUrl || 'https://via.placeholder.com/80.png?text=No+Image'}
                                        alt={guest.name}
                                        onError={(e) => e.target.src = 'https://via.placeholder.com/80.png?text=No+Image'}
                                        loading="lazy"
                                        style={{
                                            borderRadius: '50%',
                                            width: '80px',
                                            height: '80px',
                                            objectFit: 'cover',
                                        }}
                                    />
                                    <div className={styles.guest_info}>
                                        <h4>{guest.name}</h4>
                                        <p>{guest.email}</p>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>No guests found for this event.</p>
                        )}
                    </div>
                </div>
                {isModalOpen && selectedGuest && selectedGuestMatch && (
                    <div
                        className={styles.modal}
                        onClick={closeModal}
                        role="dialog"
                        aria-modal="true"
                        aria-labelledby="modal-title"
                    >
                        <div
                            className={styles.modal_content}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <span
                                className={styles.close_button}
                                onClick={closeModal}
                                aria-label="Close Modal"
                            >
                                &times; 
                            </span>
                            <div className={styles.modal_header}>
                                <h3 id="modal-title">{selectedGuestMatch?.name}'s Images</h3>
                                <div className={styles.email_section}>
                                    <FaEnvelope className={styles.email_icon} />
                                    <span className={styles.email_address}>{selectedGuestMatch?.email}</span>
                                </div>
                            </div>
                            <div className={styles.images_container_whole}>
                                <div className={styles.modal_images_container}>
                                    {selectedGuestMatch?.matches && selectedGuestMatch.matches.length > 0 ? (
                                        selectedGuestMatch.matches.map((match, index) => (
                                            <div key={index} className={styles.image_contents}>
                                                <img
                                                    src={match.imageUrl}
                                                    alt={`${selectedGuestMatch?.name} Image ${index + 1}`}
                                                    className={styles.modal_image}
                                                    loading="lazy"
                                                    onClick={() => handleImageClick(match.imageUrl)}
                                                    onError={(e) => (e.target.src = 'https://via.placeholder.com/200x150.png?text=No+Image')}
                                                />
                                                <p>Confidence: {match.confidence ? match.confidence.toFixed(2) : 'N/A'}%</p>
                                            </div>
                                        ))
                                    ) : (
                                        <p>No images available.</p>
                                    )}
                                </div>
                            </div>
                            {lightboxOpen && (
                                <div className={styles.lightbox_overlay} onClick={() => setLightboxOpen(false)}>
                                    <div className={styles.lightbox_content} onClick={(e) => e.stopPropagation()}>
                                        <img src={lightboxImage} alt="Enlarged view" />
                                        <button className={styles.lightbox_close} onClick={() => setLightboxOpen(false)}>
                                            &times;
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
