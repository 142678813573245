import React from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import folder from '../img/folder.svg';
import styles from './Album.module.css';
import { useRef, useEffect, useState } from 'react';
import open from '../img/open.svg';
import { CircularProgress } from '@material-ui/core';
import checkmark from '../img/checkmark.svg';
import selection from '../img/selection.svg';
import upload from '../img/upload.svg';
import ErrorModal from '../componets/folderModal/ErrorModal';
import { useSelector } from 'react-redux';
import { UserActions } from '../Store/userSlice';
import { useDispatch } from 'react-redux';
import ImageCompressor from 'image-compressor.js';
import { CiViewTable } from "react-icons/ci";
import { MdArrowOutward } from "react-icons/md";
import { v4 as uuidv4 } from 'uuid'; 
// import ImageViewer from '../componets/ImageViewer';
// import Gallery from 'react-image-gallery';
// import 'react-image-gallery/styles/css/image-gallery.css';
import { Gallery } from 'react-grid-gallery';
// import Lightbox from 'react-image-lightbox';
// import 'react-image-lightbox/style.css';
import Modal from 'react-modal';
import CircularProgressWithLabel from '../componets/Loadinglabel'
import { ThumbnailImageProps } from "react-grid-gallery";
// import ProgressBar from 'react-bootstrap/ProgressBar';
// import 'bootstrap/dist/css/bootstrap.min.css';
import ProgressBar from '../componets/progressModal/Progress'
import UploadProgress from '../componets/progressModal/Progress';

function Album() {
  const forceUpdate = useRef(0);
  const dispatch = useDispatch();
  const userStatus = useSelector(state => state.user);
  const userSelection = useSelector(state => state.user.selected);
  const navigate = useNavigate();
  const foldersRef = useRef(null);
  const hiddenFileInput = useRef(null);

  // const { clientName, eventDate } = useParams();
  const { clientName, eventDate, medium, select, id } = useParams();
  const [showSelected, setSelected] = useState('');
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [uploadTrigger, setUploadTrigger] = useState(false);

  const [originals, setOriginals] = useState([]);
const [thumbnails, setThumbnails] = useState([]);


  const [totalFiles, setTotalFiles] = useState(0);
  // const [uploadDoubleTrigger, setUploadDoubleTrigger] = useState(false);
  /////////////////////////////////////////////////////////////////
  const [loading, setLoading] = useState(false);
  // const [select, setSelect] = useState('');
  const [progress, setProgress] = useState(null);

  const [images, setImages] = useState([]);



  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(null);
  const [mode, setMode] = useState('selection');
  const [isGalleryModalOpen, setGalleryModalOpen] = useState(false);

  const openLightbox = (index) => {
    setSelectedPhotoIndex(index);
    setLightboxIsOpen(true);
  };

  const closeLightbox = () => {
    setLightboxIsOpen(false);
  };

  const toggleMode = () => {
    setMode(prevMode => prevMode === 'selection' ? 'lightbox' : 'selection');
  };
  // const handleImageClick = (index) => {
  //   openLightbox(index);
  //   console.log('Image clicked!', index);
  // };
  const handleImageClick = (originalUrl) => {
    console.log('Image clicked!', originalUrl);
    setSelectedPhotoIndex(originalUrl); // Use the original URL as the index/reference
    setLightboxIsOpen(true);
  };
  

  const handleDeleteClick = (index) => {
    alert("Are you sure you want to delete the image?")
  };

  // useEffect(() => {
  //   console.log('Here');
  //   console.log(userSelection.selection);
  // })


  const [eventData, setEventData] = useState({
    _id: '',
    userId: '',
    EventCategory: '',
    Groom: '',
    Bride: '',
    EventName: '',
    Venue: '',
    ClientName: '',
    Email: '',
    Date: '',
    Source: '',
    Phone: '',
    magicLink: '',
    __v: 0,
  });



//#################################////////////

// const handleFileChange = async (e) => {
//   const selectedFiles = e.target.files;
//   setTotalFiles(selectedFiles.length);

//   dispatch(UserActions.setTotalUploads({
//     Total: selectedFiles.length,
//   }));
//   uploadFiles(selectedFiles);
// };

// const uploadFiles = async (files) => {
//   const CHUNK_SIZE = 5; 
//   const totalFiles = files.length;
//   let uploadedFiles = 0;
//   let main;

//   const uploadChunk = async (chunk) => {
//     for (const file of chunk) {
//       if (file instanceof File) {
//         const compressedFile = await compressImage(file);
        
//         try {
//           const response = await axios.get('https://api.hapzea.com/api/v1/user/generate-signed-url', {
//             withCredentials: true,
//             params: {
//               id: id,
//               main_folder: main,
//               sub_folder: select,
//               fileName: compressedFile.name,
//               fileType: compressedFile.type,
//             },
//           });

//           const { signedUrl, publicUrl } = response.data;

//           console.log('glooooooooooooo');
//           console.log(signedUrl);

//           await axios.put(signedUrl, compressedFile, {
//             headers: {
//               'Content-Type': compressedFile.type,
//             },
//             onUploadProgress: (progressEvent) => {
//               const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
//               dispatch(UserActions.setUploadProgress({
//                 fileName: compressedFile.name,
//                 progress,
//                 status: 'uploading',
//               }));
//               console.log(`Progress for ${compressedFile.name}: ${progress}%`);
//             },
//           });

//           uploadedFiles += 1;
//           dispatch(UserActions.setUploadProgress({
//             fileName: compressedFile.name,
//             progress: 100,
//             status: 'completed',
//           }));

//           console.log(`File uploaded successfully: ${compressedFile.name}`);

//         } catch (error) {
//           console.error('Error uploading file:', error);
//           dispatch(UserActions.setUploadProgress({
//             fileName: compressedFile.name,
//             progress: 0,
//             status: 'failed',
//           }));
//         }
//       } else {
//         console.error('Invalid file type:', file);
//       }
//     }
//   };

//   const uploadInChunks = async (files) => {
//     for (let i = 0; i < files.length; i += CHUNK_SIZE) {
//       const chunk = Array.from(files).slice(i, i + CHUNK_SIZE);
//       await uploadChunk(chunk);
//     }
//     setUploadTrigger(true);
//     console.log('All chunks uploaded and state updated.');
//   };

//   try {
//     if (medium === 'choose') {
//       main = 'PhotoSelection';
//     }

//     if (medium === 'set') {
//       main = 'Album';
//     }

//     await uploadInChunks(files);
//     await fetchingNon();
//   } catch (error) {
//     console.error('Error uploading files:', error.message);
//   }
// };




// const compressImage = async (file) => {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.onload = (event) => {
//       const img = new Image();
//       img.src = event.target.result;

//       img.onload = () => {
//         const canvas = document.createElement('canvas');
//         const ctx = canvas.getContext('2d');
//         canvas.width = img.width;
//         canvas.height = img.height;

//         ctx.drawImage(img, 0, 0, img.width, img.height);

//         canvas.toBlob((blob) => {
//           if (blob) {
//             resolve(new File([blob], file.name, { type: 'image/jpeg', lastModified: Date.now() }));
//           } else {
//             reject(new Error('Compression failed'));
//           }
//         }, 'image/jpeg', 0.7);
//       };

//       img.onerror = () => {
//         reject(new Error('Image loading failed'));
//       };
//     };

//     reader.onerror = () => {
//       reject(new Error('File reading failed'));
//     };

//     reader.readAsDataURL(file);
//   });
// };

// #################################////////////



  const handleFileChange = async (e) => {
    const selectedFiles = e.target.files;
    const uniqueFiles = Array.from(new Set(selectedFiles)); 
    setTotalFiles(uniqueFiles.length);
    dispatch(UserActions.setTotalUploads({
      Total: selectedFiles.length
    }));
    console.log('%^%^%^%^%^');
    console.log(selectedFiles.length);
    console.log(selectedFiles);
    console.log('from handleFileChange');
    uploadFiles(selectedFiles);
  };
  
//////////////////////////////////////

const uploadFiles = async (files) => {
  const CHUNK_SIZE = 5; 
  const totalFiles = files.length;
  let uploadedFiles = 0;
  let main; 

  const uploadChunk = async (chunk) => {
    const formData = new FormData();

    // Assign unique IDs to each file to prevent name collisions
    const fileIDs = chunk.map(file => ({ id: uuidv4(), file }));

    // Compress and append files to FormData
    for (const { id, file } of fileIDs) {
      if (file instanceof File) {
        const compressedFile = await compressImage(file);
        formData.append('photos', compressedFile, `${id}-${file.name}`);
        // Initialize progress in Redux
        dispatch(UserActions.setUploadProgress({
          fileName: `${id}-${file.name}`,
          progress: 0,
          status: 'uploading'
        }));
      } else {
        console.error('Invalid file type:', file);
      }
    }

    try {
      const response = await axios.post('https://api.hapzea.com/api/v1/user/upload', formData, {
        withCredentials: true,
        params: {
          id: id,
          main_folder: main,
          sub_folder: select,
        }, 
        onUploadProgress: (progressEvent) => {
          const batchProgress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          
          // Update each file's progress
          fileIDs.forEach(({ id, file }) => {
            dispatch(UserActions.setUploadProgress({
              fileName: `${id}-${file.name}`,
              progress: Math.min(batchProgress, 100),
              status: batchProgress < 100 ? 'uploading' : 'completed'
            }));
          });

          console.log(`Batch Progress: ${batchProgress}%`);
        }
      });

      if (response.status === 200) {
        console.log('Chunk uploaded successfully.');
        uploadedFiles += chunk.length;

        // Finalize upload status
        fileIDs.forEach(({ id, file }) => {
          dispatch(UserActions.setUploadProgress({
            fileName: `${id}-${file.name}`,
            progress: 100,
            status: 'completed'
          }));
        });

        setTimeout(() => {
          setUploadTrigger(true);
        }, 2000);
      } else {
        console.error('Error uploading chunk:', response.statusText);
        // Mark all files in the chunk as failed
        fileIDs.forEach(({ id, file }) => {
          dispatch(UserActions.setUploadProgress({
            fileName: `${id}-${file.name}`,
            progress: 0,
            status: 'failed'
          }));
        });
      }
    } catch (error) { 
      console.error('Error uploading chunk:', error.message);
      
      // Mark all files in the chunk as failed
      fileIDs.forEach(({ id, file }) => {
        dispatch(UserActions.setUploadProgress({
          fileName: `${id}-${file.name}`,
          progress: 0,
          status: 'failed'
        }));
      });
    }
  };

  const uploadInChunks = async (files) => {
    for (let i = 0; i < files.length; i += CHUNK_SIZE) {
      const chunk = Array.from(files).slice(i, i + CHUNK_SIZE);
      await uploadChunk(chunk);
    }
    setUploadTrigger(true);
    console.log('All chunks uploaded and state updated.');
  };

  try {
    if (medium === 'choose') {
      main = 'PhotoSelection';
    }

    if (medium === 'set') {
      main = 'Album';
    }

    // Start uploading files in chunks
    await uploadInChunks(files);
    await fetchingNon();
  } catch (error) {
    console.error('Error uploading files:', error.message);
  }
};



  //////////////////////////
  

  const compressImage = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const img = new Image();
        img.src = event.target.result;

        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.width = img.width;
          canvas.height = img.height;

          ctx.drawImage(img, 0, 0, img.width, img.height);

          canvas.toBlob(
            (blob) => {
              resolve(new File([blob], file.name, { type: 'image/jpeg', lastModified: Date.now() }));
            },
            'image/jpeg',
            0.7
          );
        };
      };

      reader.readAsDataURL(file);
    });
  };


  const handleUploadClick = () => {
    document.getElementById('fileInput').click();
    // fetchDatas()
  };

  const [folders, setFolders] = useState([
  ]);

  const [photos, setPhotos] = useState([

  ]);

  const folderPairs = [];
  for (let i = 0; i < folders.length; i += 2) {
    const pair = [folders[i], folders[i + 1]];
    folderPairs.push(pair.filter(folder => folder));

    console.log(folderPairs);
  }

  const [isPopupVisible, setPopupVisibility] = useState(false);
  const [folderName, setFolderName] = useState("");
  const [folderError, setErrorMsg] = useState("");

  const [foldersArray, setfoldersArray] = useState([]);



  const clearimage = async () => {
    console.log('clearing');
    setImages([]);
  }

  useEffect(() => {
    setProgress(null)
  }, [])


  const fetchingNon = async () => {
    try {
      console.log('Iam Fu**');
      let isMounted = true;
      console.log(`selected one ${select}`);
      let main;
      if (medium === 'choose') {
        main = 'PhotoSelection';
      }
      if (medium === 'set') {
        main = 'Album';
      }
      const response = await axios.get(`https://api.hapzea.com/api/v1/user/fetchMedia`, {
        params: {
          id: id,
          main_folder: main,
          sub_folder: select,
        },
        withCredentials: true,
        // onDownloadProgress: (progressEvent) => {
        //   const uploadProgress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
        //   console.log(uploadProgress); 
        //   setProgress(uploadProgress);
        //   if (uploadProgress === 100) {
        //     setTimeout(() => {
        //       setProgress(null);
        //     }, 1000); 
        //   }
        //   console.log(uploadProgress);
        // }
      });
      const fetchedPhotos = response.data.data.files || [];
      if (isMounted) {
      // Separate originals and thumbnails based on URL pattern
      // const originals = fetchedPhotos.filter(photo => photo.includes('/originals/'));
      // const thumbnails = fetchedPhotos.filter(photo => photo.includes('/thumbnails/'));
      // setOriginals(originals);
      // setThumbnails(thumbnails);

      // console.log('thumbnails ##');
      // console.log(thumbnails);

        setImages(
          fetchedPhotos.map((photo, index) => ({
            src: photo,
            thumbnail: photo,
            thumbnailWidth: 320,
            thumbnailHeight: 212,
            isSelected: selectedPhotoIndex === index,
            onClick: () => handleImageClick(index),
          }))
        );
      }

      setPhotos(fetchedPhotos);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    const fetchDataAndSetState = async () => {
      try {
        let isMounted = true;
        console.log(`selected one ${select}`);
        let main;
        if (medium === 'choose') {
          main = 'PhotoSelection';
        }
        if (medium === 'set') {
          main = 'Album';
        }
        const response = await axios.get(`https://api.hapzea.com/api/v1/user/fetchMedia`, {
          params: {
            id: id,
            main_folder: main,
            sub_folder: select,
          },
          withCredentials: true,
          // onDownloadProgress: (progressEvent) => {
          //   const uploadProgress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          //   console.log(uploadProgress); 
          //   setProgress(uploadProgress);
          //   if (uploadProgress === 100) {
          //     setTimeout(() => {
          //       setProgress(null);
          //     }, 1000); 
          //   }
          //   console.log(uploadProgress);
          // }
        });
        const fetchedPhotos = response.data.data.files || [];

        console.log('starting');
        const originals = fetchedPhotos.filter(photo => photo.includes('/originals/'));
        const thumbnails = fetchedPhotos.filter(photo => photo.includes('/thumbnails/'));
        console.log('end');
        console.log('fetchedPhotos $$:',fetchedPhotos);
        console.log('Fetched thumbnails ##:', thumbnails);
        setOriginals(originals);
        setThumbnails(thumbnails);
  
        console.log('based on state change');
        console.log(fetchedPhotos);
        if (isMounted) {
          setImages(
            fetchedPhotos.map((photo, index) => ({
              src: photo,
              thumbnail: photo,
              thumbnailWidth: 320,
              thumbnailHeight: 212,
              isSelected: selectedPhotoIndex === index,
              onClick: () => handleImageClick(index),
            }))
          );
        }
  
        setPhotos(fetchedPhotos);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    console.log('PHOTOSSSSS');
    console.log(photos);
    fetchDataAndSetState();
    setUploadTrigger(false);
    setLoading(false);
    return () => {
      clearimage();
    };
  }, [id, medium, select, uploadTrigger]);
  


  const handleAddFolderClick = () => {
    setPopupVisibility(true);
    setErrorMsg("");
  };

  const handleCancelAddFolder = () => {
    setPopupVisibility(false);
    setFolderName("");
  };

  const clearError = () => {
    setErrorMsg("");
  };



  const sendtarget = (event, folder) => {
    dispatch(UserActions.updateSelected({ parent: 'Album', selection: `${folder.name}` }));
  };


  const fetchData = async (id) => {
    try {
      const response = await axios.get('https://api.hapzea.com/api/v1/user/getFiles', {
        params: {
          _id: id
        },
        withCredentials: true,
      });
      const { album, photo } = response.data.data;
      console.log('$$$$$$$$$$$$$$');
      console.log({ album, photo });
      const main = { album, photo }
      dispatch(UserActions.firstfiles([album, photo]));
      return main
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    const replaceUserAsync = async (clientData) => {
      return new Promise((resolve, reject) => {
        dispatch(UserActions.replaceUser(clientData));
        resolve();
      });
    };

    const fetchData = async () => {
      try {

        if (id !== undefined) {
          const response = await axios.get(`https://api.hapzea.com/api/v1/user/client/${id}`, {
            withCredentials: true,
          });

          await replaceUserAsync(response.data.data.client);
        }
      } catch (error) {
      }
    };
    fetchData();
  }, [id]);


  async function createFolder(id, nameFolder, media) {
    try {
      console.log('iiiiiiiiiiiiiiiiddddddddddd');
      console.log(id);
      const requestData = {
        folder_name: nameFolder,
        main_folder: media,
      };



      const response = await axios.post(
        'https://api.hapzea.com/api/v1/user/createfolder',
        requestData,
        {
          params: {
            _id: id
          },
          withCredentials: true,
        }
      );

      console.log('Folder creation successful:', response.data);
      fetchData(userStatus.user._id);
    } catch (error) {
      console.error('Error creating folder:', error.message);
    }
  }

  const handleAddFolder = async () => {
    const trimmedFolderName = folderName.trim();

    if (trimmedFolderName !== '') {
      const newFolder = {
        name: trimmedFolderName,
        icon: folder,
      };

      const check = typeof select === 'object'
        ? JSON.stringify(select)
        : select;

      if (medium === 'choose') {
        await createFolder(id, trimmedFolderName, 'PhotoSelection');
        setPopupVisibility(false);
        setFolderName('');
        fetchDataAndSetState()
      }
      if (medium === 'set') {
        await createFolder(id, trimmedFolderName, 'Album');
        setPopupVisibility(false);
        setFolderName('');
        fetchDataAndSetState()
      }
    } else {
      setErrorMsg('Please enter a folder name.');
    }
  };



  const fetchDataAndSetState = async () => {
    const fileSet = await fetchData(id);
    const check = typeof select === 'object'
      ? JSON.stringify(select)
      : select;
    if (medium === 'choose') {
      console.log('#$#$#$#$#$#$#$#$#$');
      // console.log(fileSet.photo.subdirectoriesList);
      // console.log(fileSet.photo.foldersInSubdirectories[check]);
      // setfoldersArray(fileSet.photo.foldersInSubdirectories[check])
      setfoldersArray(fileSet.photo)
    }
    if (medium === 'set') {
      console.log('#$#$#$#$#$#$#$#$#$');
      // console.log(fileSet.photo.subdirectoriesList);
      // console.log(fileSet.album.foldersInSubdirectories[check]);
      // setfoldersArray(fileSet.album.foldersInSubdirectories[check])
      setfoldersArray(fileSet.album)
    }
  };


  useEffect(() => {
    fetchDataAndSetState();
    var currentUrl = window.location.href;
    var urlParams = new URLSearchParams(currentUrl);
    var selectValue = urlParams.get('select');
  }, []);


  useEffect(() => {
    const initialFolders = foldersArray.map((folderName) => ({
      name: folderName,
      icon: folder,
    }));

    // Set the initial folders to the state
    setFolders(initialFolders);

  }, [foldersArray]);


  useEffect(() => {
    // console.log(folderPairs);
    const foldersContainer = foldersRef.current;
    const isScrollable = folderPairs.length > 3;

    if (isScrollable) {
      foldersContainer.style.overflowX = 'scroll';
    } else {
      foldersContainer.style.overflowX = 'hidden';
    }
  }, [folderPairs]);

  const setFolderChange = (e) => {
    setFolderName(e.target.value)
  }


  const galleryImages = photos.map((photo, index) => ({
    src: photo,
    thumbnail: photo,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    isSelected: selectedPhotoIndex === index,
  }));

  const handleSelect = (index) => {
    const updatedImages = images.map((image, i) =>
      i === index ? { ...image, isSelected: !image.isSelected } : image
    );
    setImages(updatedImages);
  };


  const [index, setIndex] = useState(-1);

  const currentImage = images[index];
  const nextIndex = (index + 1) % images.length;
  const nextImage = images[nextIndex] || currentImage;
  const prevIndex = (index + images.length - 1) % images.length;
  const prevImage = images[prevIndex] || currentImage;

  const handleClick = (index, item) => setIndex(index);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  const morePhotos = () => {
    console.log('view more');
    console.log('$$$$$321231313$$$$$$$');
    console.log(userSelection);
    var url = `/uploads/${userStatus.user.ClientName}/${userStatus.user.Date}/${id}/${medium}/folders/${select}/view_more`;
    window.open(url, '_blank');
  }


  return (
    <div className={styles.folderPortion}>
      {/* {loading && (
       
      )} */}
     
      {isPopupVisible && (
        <ErrorModal
          onCancel={handleCancelAddFolder}
          onChange={setFolderChange}
          onConfirm={handleAddFolder}
          errorMessage={folderError}
          folderName={folderName}
          clearError={clearError}
        />
      )}
      <div className={styles.folderOuter}>
        <div className={styles.foldersOuterStk} ref={foldersRef}>
          {folderPairs.map((pair, pairIndex) => (
            <div key={pairIndex} className={styles.foldersStack}>
              {pair.map((folder, folderIndex) => (
                <Link key={folderIndex} onClick={(event) => sendtarget(event, folder)}
                  className={`${userSelection.selection.length === 0 ? (select === folder.name ? styles.selectedFolder : styles.normalrange) : (userSelection.selection === folder.name ? styles.selectedFolder : styles.normalrange)}`}
                  to={`/uploads/${clientName}/${eventDate}/${id}/${medium}/folders/${folder.name}`}>
                  <span>
                    <img src={folder.icon} alt="Folder Icon" />
                  </span>
                  {folder.name}
                </Link>
              ))}
            </div>
          ))}
        </div>
      </div>

      <p className={styles.folderAdd} onClick={handleAddFolderClick}>+ Folder</p>
      <div className={styles.UploadBox}>
        <div className={styles.sectionTwo}>
          <div className={styles.photoHead}>
            <div className={styles.contentHead}>
              { }
              <p>{userSelection.selection.length === 0 ? select : userSelection.selection}</p>
              <p className={styles.NumPhotos}>{photos.length/2} Photos</p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.photoBody}>
        <div className={styles.photoBodyBarrier}>
          <div className={styles.uploadborder}>
            <div className={styles.uploadCenter}>
              <img src={upload} alt="Upload Icon" />
              <p className={styles.DropHere}>Drop Photos here</p>
              <p className={styles.DropTag}>Uploading multiple photos may take some time. Please be patient.</p>
              <div className={styles.UploadingBtn} onClick={handleUploadClick}>
                Upload
              </div>
              <input
                type="file"
                id="fileInput"
                onChange={handleFileChange}
                multiple
                ref={hiddenFileInput}
                style={{ display: 'none' }}
              />
              {/* <UploadProgress/> */}
              {/* {progress && (
                <div className={styles.progressBar}>
                  <ProgressBar progress={progress} />
                </div>
              )} */}

              {/* <div className={styles.progressBar}>
    <ProgressBar now={progress} label={`${progress}%`} />
    <ProgressBar progress={50}/>
  </div> */}


            </div>
          </div>
        </div>
      </div>

      <div className={styles.spaceout}></div>
      {/* <Gallery images={galleryImages}
        onClick={handleImageClick}
      /> */}
      {/* <Gallery images={images} onSelect={handleSelect} /> */}
      {/* <div> 
    <button onClick={toggleMode}>
          {mode === 'selection' ? "Switch to view" : "Switch to Select"}
        </button>
    </div> */}
      {/* <Gallery  
        images={images}
        onClick={handleClick}
        enableImageSelection={true}
    /> */}

      <div className={styles.wide}>
        <button className={styles.ViewMoreButton} onClick={morePhotos}>
          View More
          <span >
            <MdArrowOutward />
          </span>
        </button>
        <div className={styles.Gallary}>
        {Array.isArray(thumbnails) && thumbnails.slice(0, 8).map((photo, index) => (
      <div key={index} className={styles.GalleryTile}>
        <img src={photo} alt={`Photo ${index + 1}`} />
      </div>
    ))}
        </div>
      </div>

      {/* {currentImage && (
        <Lightbox
          mainSrc={currentImage.src}
          imageTitle={currentImage.caption}
          mainSrcThumbnail={currentImage.src}
          nextSrc={nextImage.src}
          nextSrcThumbnail={nextImage.src}
          prevSrc={prevImage.src}
          prevSrcThumbnail={prevImage.src}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
      )} */}


    </div>
  )
}

export default Album 
