import React, { useState, useEffect } from 'react';
import { IoMdHeartEmpty, IoMdHeart, IoMdDownload } from 'react-icons/io';
import styles from './FaceRecognition_Gallery.module.css';
import { useParams } from 'react-router-dom';
import FaceImageViewer from './FaceImageViewer'; 
import axios from 'axios';

const FaceRecognitionGallery = () => {
  const { id } = useParams();
  const { eventId } = useParams();
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [favoriteImages, setFavoriteImages] = useState([]);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(`https://api.hapzea.com/api/v1/user/user-detailing/${eventId}`);
        console.log("response.data.data.user");
        console.log(response.data.data.user);
        setUserData(response.data.data.user);
      } catch (err) {
        setError('Something went wrong while fetching user details.');
      }
    };
  
    if (eventId) fetchUserDetails();
  }, [eventId]);

  useEffect(() => {
    const fetchMatchedImages = async () => {
      if (!id) {
        setError('No Guest ID provided.');
        return;
      }

      setLoading(true);
      setError(null);

      try {
        const response = await axios.get('https://api.hapzea.com/api/v1/user/matched-images', {
          params: { id },
        });

        if (response.data && response.data.matchedImages) {
          const fetchedImages = response.data.matchedImages.map((match, index) => ({
            id: `${match.guestId}-${index}`,
            thumbnail: match.imageUrl,
            original: match.imageUrl,
            title: `Guest_${match.guestName}`,
            description: `Confidence: ${(match.confidence * 100).toFixed(2)}%`,
          }));
          setImages(fetchedImages);
        } else {
          setImages([]);
          setError('No matched images found.');
        }
      } catch (err) {
        setError('Failed to load matched images.');
      } finally {
        setLoading(false);
      }
    };

    fetchMatchedImages();
  }, [id]);

  const downloadImage = async (url, title) => {
    try {
      const response = await fetch(`https://api.hapzea.com/download-image?url=${encodeURIComponent(url)}`);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = title;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Error during image download:', error);
      alert('Failed to download the image. Please try again later.');
    }
  };

  // New function to download all images
  const downloadAllImages = async () => {
    try {
      for (let i = 0; i < images.length; i++) {
        const image = images[i];
        await downloadImage(image.original, image.title);
      }
      alert('All images are being downloaded!');
    } catch (error) {
      console.error('Error during batch download:', error);
      alert('Failed to download all images. Please try again later.');
    }
  };

  return (
    <>
    <div className={styles.galleryContainer}>
      <div className={styles.galleryHeader}>
        <h1 className={styles.galleryTitle}>{userData.businessName || ''}</h1>
        <div className={styles.galleryTitle_Sub}>
          <p>FOR WEDDING, PHOTOGRAPHERS, PORTFOLIO, LOOKBOOK, BABYSHOWER, FAMILY AND MORE</p>
        </div>
        <div className={styles.galleryTitle_MobEmail}>
          <p>MOB:{userData.mobile || ''}</p>
          <p>EMAIL:{userData.email || ''}</p>
        </div>
      </div>
      <div className={styles.downloadAllContainer}>
    <button className={styles.downloadAllButton} onClick={downloadAllImages}>
      Download All
    </button>
  </div> 

      {loading && <p className={styles.loading}>Loading matched images...</p>}
      {error && <p className={styles.error}>{error}</p>}

      {!loading && !error && images.length === 0 && <p className={styles.noImages}>No matched images found for this guest.</p>}

      <div className={styles.galleryGrid}>
        {images.map((image, index) => (
          <div key={image.id} className={styles.galleryItem}>
            <div className={styles.imageWrapper} onClick={() => setCurrentIndex(index)}>
              <img
                src={image.thumbnail}
                alt={image.title}
                className={styles.galleryImage}
                loading="lazy"
              />
            </div>
            <div className={styles.actions}>
              <button
                className={styles.actionButton}
                onClick={() => setFavoriteImages((prev) => 
                  prev.includes(image.id) ? prev.filter((id) => id !== image.id) : [...prev, image.id])}
                aria-label="Favorite"
              >
                {favoriteImages.includes(image.id) ? <IoMdHeart className={styles.favoriteIconActive} /> : <IoMdHeartEmpty className={styles.favoriteIcon} />}
              </button>
              <button
                className={styles.actionButton}
                onClick={() => downloadImage(image.original, image.title)}
                aria-label="Download"
              >
                <IoMdDownload className={styles.downloadIcon} />
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Download All Button */}
      {/* <div className={styles.downloadAllContainer}>
        <button className={styles.downloadAllButton} onClick={downloadAllImages}>
          Download All
        </button>
      </div> */}

      {currentIndex >= 0 && (
        <FaceImageViewer
          images={images.map((img) => ({ url: img.original, alt: img.description || img.title }))}
          currentIndex={currentIndex}
          onClose={() => setCurrentIndex(-1)}
          favoriteImages={favoriteImages}
          toggleFavorite={(id) => setFavoriteImages((prev) => 
            prev.includes(id) ? prev.filter((favId) => favId !== id) : [...prev, id])}
        />
      )}
    </div>
    </>
  );
};

export default FaceRecognitionGallery;


