import React, { useEffect, useState } from "react";
import logo from '../img/logo_test_white.png';
import styles from './DesktopLogin.module.css'; // Import the CSS file for styling
import googleIcon from "../img/g-icon.png"; // Path to your Google icon asset


const DesktopLogin = () => {
  const [status, setStatus] = useState("Authenticating...");

  useEffect(() => {
    const fragment = window.location.hash.substring(1);
    const params = new URLSearchParams(fragment);

    const idToken = params.get("id_token");
    const accessToken = params.get("access_token");

    if (idToken && accessToken) {
      const appLink = `hapzea://localhost?id_token=${idToken}&access_token=${accessToken}`;

      setTimeout(() => {
        setStatus("Google Authentication Successful. You can close this window now.");
        setTimeout(() => {
          window.location.href = appLink;
        }, 1500);
      }, 1000);
    } else {
      setStatus("Authentication Failed. Please try again.");
    }
  }, []);

  return (
    <div className={styles.desktopLogin}>
      {/* Top-left logo */}
      <img src={logo} alt="Logo" className={styles.desktopLoginLogo} />
      {/* Main content */}
      <div className={styles.desktopLoginContent}>
        <div className={styles.googleIconContainer}>
          <div className={styles.googleIconBox}>
            <img src={googleIcon} alt="Google Icon" className={styles.googleIcon} />
          </div>
        </div>
        <h1 className={styles.desktopLoginStatus}>{status}</h1>
      </div>
    </div>
  );
};

export default DesktopLogin;
